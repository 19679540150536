<template>
  <div
    v-if="isShow"
    class="all-token-list"
    @click.stop.prevent="selectToken"
  >
    <div
      v-for="item in tokenList"
      :key="item.id"
      class="card-block"
      :class="[
        {
          'card-block__disabled': 'isGray' in item
        }
      ]"
    >
      <div class="item-left">
        <s-radio
          v-model="nowRadio"
          class="payment-item-radio payment-item__effiency-radio"
          size="radio20"
          theme="icon"
          gap="0"
          :value="item.id"
          :label="item.id"
          :disabled="'isGray' in item"
          @change="handlerCheck(item)"
        />
      </div>
      <div class="item-right">
        <div class="option-item">
          <TokenCardImg
            :item="item"
            :locals="locals"
          />
          <div class="card-detail-wrap">
            <span class="card-detail-number">
              <span class="card-no-text">{{ item.cardNo }}</span>
              <PaymentDiscount
                :language="language"
                :checkout="checkout"
                :card-bin="item.cardBin"
                :is-show-discount-tips="isShowDiscountTips"
                :is-had-show-online-pay-discount="isHadShowOnlinePayDiscount"
                :is-show-random-discount="isShowRandomDiscount"
                :random-discount-des="randomDiscountDes"
                :get-online-pay-discount-value="getOnlinePayDiscountValue"
                :mall-caculate-info="mallCaculateInfo"
                :is-show-token-payment="isShowTokenPayment"
                :is-show-online-pay-discount="isShowOnlinePayDiscount"
                :is-show-card-bin-discount="isShowCardBinDiscount"
                :card-bin-discount-desc="cardBinDiscountDesc"
                :item="paymentItem"
                :card-bin-discount-info="cardBinDiscountInfo"
                :is-support-bin="isSupportBin"
                :is-show-token-front-installment="isShowTokenFrontInstallment"
              />
            </span>
          </div>
          <Icon
            name="sui_icon_delete_16px_1"
            class="del-icon"
            size="16px"
            @click="handleDel(item)"
          />
        </div>

        <div
          v-if="'isGray' in item"
          class="disabled-tip"
        >
          <Icon
            name="sui_icon_reported_12px_1"
            size="12px"
          />
          <span>
            {{ language.SHEIN_KEY_PC_24741 }}
          </span>
        </div>
      </div>
    </div>

    <s-dialog
      :visible="isShowDelDialog"
      :show-close="true"
      :append-to-body="true"
      class="token-dialog"
      @close="closeDialog"
    >
      <div class="token-pay-del">
        <span>
          {{ language.SHEIN_KEY_PC_20474 }}
        </span>
      </div>

      <template #footer>
        <SButton
          :type="['default', 'H36PX']"
          width="150px"
          style="margin-right: 12px"
          @click="closeDialog"
        >
          {{ language.SHEIN_KEY_PC_20475 }}
        </SButton>
        <SButton
          :type="['primary', 'H36PX']"
          width="150px"
          @click="onClickConfirm"
        >
          {{ language.SHEIN_KEY_PC_20476 }}
        </SButton>
      </template>
    </s-dialog>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, computed } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { SRadio } from '@shein-aidc/sui-radio'
import { SDialog } from '@shein-aidc/sui-dialog'
import { SButton } from '@shein-aidc/sui-button'
import TokenCardImg from './TokenCardImg.vue'
import PaymentDiscount from 'public/src/pages/checkout/vue_tpls/token_pay/pay_discount.vue'
import { useStore } from 'vuex'
import { deletePaymentSignUp } from 'public/src/services/api/pay/payment'
import { SMessage } from '@shein-aidc/sui-message'

const props = defineProps({
  isShowAllTokenList: {
    type: Boolean,
    default: false
  },
  language: {
    type: Object,
    default: () => {}
  },
  isShowDiscountTips: {
    type: [Boolean, Number],
    default: false
  },
  isHadShowOnlinePayDiscount: {
    type: Boolean,
    default: false
  },
  isShowRandomDiscount: {
    type: [Boolean, Number],
    default: false
  },
  randomDiscountDes: {
    type: Function
  },
  paymentItem: {
    type: Object,
    default: () => {}
  },
  getOnlinePayDiscountValue: {
    type: Object,
    default: () => {}
  },
  mallCaculateInfo: {
    type: Object,
    default: () => {}
  },
  isShowTokenPayment: {
    type: Boolean,
    default: false
  },
  isShowTokenFrontInstallment: {
    type: Boolean,
    default: false
  },
  isShowOnlinePayDiscount: {
    type: Boolean,
    default: false
  },
  isShowCardBinDiscount: {
    type: [Boolean, Number],
    default: false
  },
  cardBinDiscountDesc: {
    type: String,
    default: ''
  },
  cardBinDiscountInfo: {
    type: Object,
    default: () => {}
  },
  locals: Object,
  isSupportBin: {
    type: Boolean,
    default: false
  },
  payItem: {
    type: Object,
    default: () => {}
  }
})

const isShow = ref(false)
const nowRadio = ref('')
const isShowDelDialog = ref(false)
const nowDelItem = ref({})
const isSelected = ref(false)
const tokenList = ref([])

let store = useStore()

const checkout = computed(() => {
  return store?.state?.checkout
})

const defaultSelectTokenPay = computed(() => {
  return store?.state?.defaultSelectTokenPay
})

const installmentSelectToken = computed(() => {
  return store?.state?.installmentSelectToken
})

const installmentSelectBin = computed(() => {
  return store?.state?.installmentSelectBin
})

const handlerSelectBin = computed(() => {
  return store?.state?.handlerSelectBin
})

watch(
  () => props.isShowAllTokenList,
  () => {
    if (props.isShowAllTokenList !== isShow.value) {
      isShow.value = props.isShowAllTokenList
    }
  },
  {
    immediate: true
  }
)

watch(
  () => tokenList.value,
  () => {
    if (typeof window === 'undefined') return
    Array.isArray(tokenList.value) &&
      store?.commit('assignState', {
        tokenBinList:
          tokenList.value?.filter(item => item?.cardBin?.length > 0) || []
      })
  },
  {
    deep: true,
    immediate: true
  }
)

watch(() => props.paymentItem, () => {
  if (props.payItem?.code !== 'routepay-card') {
    nowRadio.value = installmentSelectToken.value?.id
  } else {
    nowRadio.value = defaultSelectTokenPay.value?.id
  }
})

const handlerCheck = item => {
  isSelected.value = true
  let lastSelectBin =
    defaultSelectTokenPay.value?.card_bin || handlerSelectBin.value || ''

  let changeData = {
    isNoNeedCardBin: false,
    lastSelectBin,
  }
  if (props.payItem?.code !== 'routepay-card') {
    lastSelectBin = installmentSelectBin.value || ''
    changeData.installmentSelectBin = item?.cardBin || item?.card_bin
    changeData.installmentSelectToken = item
  } else {
    changeData.handlerSelectBin = item?.cardBin || item?.card_bin
  }

  store?.commit('assignState', changeData)

  handlerPayChange(item)
}

function handlerPayChange(item) {

  window.checkoutEventCenter.emit('checkout-update-payment', {
    request_card_token: 1,
    token_id: item?.id || '',
    isNeedMallCal: true,
    isInstallment: props.payItem?.code !== 'routepay-card'
  })

  const nowSelectedItem = props.payItem?.card_token_list?.find(tokenItem => tokenItem?.id === item?.id)
  

  if (props.payItem?.code === 'routepay-card') {
    store?.commit('assignState', {
      defaultSelectTokenPay: nowSelectedItem,
      handlerSelectBin: item?.cardBin || item?.card_bin || '',
    })
  } else {
    store?.commit('assignState', {
      installmentSelectToken: nowSelectedItem,
      installmentSelectBin: item?.cardBin || item?.card_bin || '',
    })
  }
}

const initData = () => {
  tokenList.value = props.payItem?.code === 'routepay-card'
    ? checkout.value?.memberPreCardTokenInfo?.data?.info?.tokenList
    : checkout.value?.installmentMemberPreCardTokenInfo?.data?.info?.tokenList

  if (props.payItem?.code !== 'routepay-card') {
    nowRadio.value = installmentSelectToken.value?.id
  } else {
    nowRadio.value = defaultSelectTokenPay.value?.id
  }
}

const handleDel = item => {
  nowDelItem.value = item
  isShowDelDialog.value = true
}

const closeDialog = () => {
  isShowDelDialog.value = false
}

const selectToken = () => {}

const onClickConfirm = () => {
  // loadingShow.value = true
  const id = nowDelItem.value?.id || ''
  const isDelNowSelect = id === nowRadio.value // 解绑的token是否是用户选中的
  
  deletePaymentSignUp({ id })
    .then(res => {
      if (res && res?.code == 0) {
        tokenList.value = tokenList.value.filter(item => item.id !== id)
        return
      }
      SMessage({
        offset: 136,
        type: 'error',
        message: props.language.SHEIN_KEY_PC_20473
      })
    })
    .catch(() => {
      SMessage({
        offset: 136,
        type: 'error',
        message: props.language.SHEIN_KEY_PC_20473
      })
    })
    .finally(() => {
      // loadingShow.value = false
      closeDialog()

      window.checkoutEventCenter.emit('checkout-update-payment', {
        request_card_token: 1,
        isNeedMallCal: isDelNowSelect,
        updateCb: (cbData) => {
          window.checkoutEventCenter?.emit('checkout-update-tokenList', { ...cbData, isDelNowSelect })
        }
      })
    })
}

const changeData = (cbData) => {
  const { isDelNowSelect } = cbData
  const nowPayment = (cbData?.info?.payments?.find(item => item?.code === props.payItem?.code) || {})
  const nowPaymentIdList = (nowPayment?.card_token_list || [])?.map(item => item?.id) || []
  const defaultTokenPay = (nowPayment?.card_token_list || [])?.find(item => item?.default_token === 1)
  tokenList.value = tokenList.value.filter(item => nowPaymentIdList.includes(item.id))
  const selectItem = tokenList.value.find(item => item.id === defaultTokenPay?.id)

  if (isDelNowSelect) {
    nowRadio.value = selectItem?.id
    handlerCheck(selectItem)
  }
}

onMounted(() => {
  initData()

  window.checkoutEventCenter?.on('checkout-update-tokenList', data => {
    changeData(data)
  })
})
</script>

<style lang="less" scoped>
.all-token-list {
  margin-top: 8px;
  .item-right {
    width: 100%;
  }
  .option-item {
    width: 100%;
    .flexbox();
    .align-center();
  }
  .card-block {
    display: flex;
    padding: 12px 4px 12px 28px;
  }

  .card-detail-wrap {
    flex: 1;
    .flexbox();
    .pack-center();
    flex-direction: column;
    overflow: hidden;
    line-height: 1.2;

    .card-detail-number {
      color: black;
      font-size: 14px;
      font-family: SF UI Text;
      font-weight: 400;
      word-wrap: break-word;
      margin-left: 8px;
      display: flex;
      align-items: center;
      .card-no-text {
        margin-right: 4px;
      }
    }
  }

  .disabled-tip {
    margin-top: 6px;
    color: #767676;
    font-size: 12px;
    font-family: SF UI Text;
    font-weight: 400;
    word-wrap: break-word;
    display: flex;
    align-items: center;

    .sui-icon-common__wrap {
      margin-right: 4px;
    }
  }

  .del-icon {
    color: #222222 !important;
    padding-right: 4px;
  }

  .payment-item-radio {
    margin-right: unset;
  }

  .card-block__disabled {
    .card-detail-number {
      color: #767676;
    }

    .del-icon {
      color: #767676 !important;
    }
  }
}
</style>
